import $ from "jquery";

import("swiper/scss");
import("swiper/scss/autoplay");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Autoplay } = await import("swiper/modules");
  $(".banner--type-1.has-slider .swiper").each(function () {
    new Swiper(this, {
      modules: [Autoplay],
      slidesPerView: 1.1,
      centeredSlides: true,
      spaceBetween: 12,
      autoplay: true,
      speed: 1000,
      loop: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
          centeredSlides: false,
          spaceBetween: 17,
        },
        767: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        992: {
          slidesPerView: 4,
          centeredSlides: false,
        },
      },
    });
  });
})();
